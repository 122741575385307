/* MUI Overrides */

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
  padding-right: 6px !important;
  background: rgb(245, 245, 245) !important;
}

.MuiSelect-select:focus {
  background-color: rgb(245, 245, 245) !important;
}

.switchContainer {
  display: flex;
  text-align: left;
  padding: 8px 0px 8px 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}
