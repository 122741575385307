.webmap {
  height: 100vh;
  width: 100%;
}

/* accessibility contrast override */
.esri-input.esri-search__input::placeholder {
  color: #767676;
}

.esri-input {
  color: #323232 !important;
}

.esri-legend__layer-cell--info {
  text-align: left;
}

.esri-ui {
  z-index: auto !important;
}
